import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import store from './store/index';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

const app = createApp(App);

const i18n = createI18n({
    legacy: false,
    locale: 'es',
    messages: {
        en: require('./locales/en.json'),
        es: require('./locales/es.json')
    }
});

app.use(i18n);
app.use(store);
app.use(router);

// Agregar un listener para el evento de versión
window.addEventListener('app-version-set', (event) => {
    document.title = `hada ${event.detail}`;
});

app.mount('#app');
