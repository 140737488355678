<template>
  <div class="descarga-declaraciones">
    <p class="h3">{{ $t("registro-declaraciones.informacion-clientes") }}</p>
    <form @submit.prevent="iniciarDescarga">
      <div class="contenedor-clientes border p-3">
        <table class="table table-hover tabla-clientes">
          <thead>
            <tr>
              <th>{{ $t("registro-declaraciones.nombre-cliente") }}</th>
              <th>{{ $t("registro-declaraciones.nif") }}</th>
              <th>{{ $t("registro-declaraciones.ruta-carpeta-cliente") }}</th>
              <th>{{ $t("registro-declaraciones.anio") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datosCliente" :key="index">
              <td>{{ item.nombre }}</td>
              <td>{{ item.nif }}</td>
              <td>{{ item.carpeta_descarga }}</td>
              <td>{{ item.anio_descarga }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row g-3" style="margin-top: 40px;">
        <div class="col-md-6">
          <label for="dropdown-certificados">{{ $t("registro-declaraciones.seleccione-certificado") }}</label>
          <select class="form-select" v-model="certificadoSeleccionado" id="dropdown-certificados">
            <option v-for="certificado in certificados" :key="certificado" :value="certificado">{{ certificado }}</option>
          </select>
          <!-- Comento el mensaje de error cuando no seleccionas 1 modelo. -->
          <!-- <div class="text-danger mt-0" v-if="sinCertificadoSeleccionado">
            {{ $t("registro-declaraciones.error-seleccione-certificado") }}
          </div> -->
        </div>
      </div>

      <div class="row g-3" style="margin-top: 35px;">
        <div class="col-md-6 form-check" style="margin-left: 10px;">
          <input class="form-check-input" type="checkbox" checked v-model="todosLosClientes" id="checkbox-todos"
            @change="limpiarSeleccion">
          <label class="form-check-label" for="checkbox-todos">{{ $t("registro-declaraciones.todos-los-clientes")
          }}</label>
        </div>
      </div>
      <div class="row g-3" style="margin-top: 10px;">
        <div class="col-md-6" :class="{ 'disabled-container': todosLosClientes }">
          <label for="dropdown-clientes" :class="{ 'disabled-label': todosLosClientes }">
            {{ $t("registro-declaraciones.seleccione-cliente") }}
          </label>
          <select class="form-select" v-model="clienteSeleccionado" id="dropdown-clientes" :disabled="todosLosClientes"
            :class="{ 'disabled-select': todosLosClientes }">
            <option :value="cliente.id" v-for="cliente in datosCliente" :key="cliente.id">{{ cliente.nombre }}</option>
          </select>
          <div class="text-danger mt-0" v-if="sinClienteSeleccionado">
            {{ $t("registro-declaraciones.error-seleccione-cliente") }}
          </div>
        </div>
      </div>

      <div class="row g-3" style="margin-top: 35px;">
        <div class="col-md-3">
          <label for="dropdown-ejercicios">{{ $t("registro-declaraciones.seleccione-ejercicio") }}</label>
          <select class="form-select" v-model="ejercicioSeleccionado" id="ejercicioDropdown">
            <option v-for="ejercicio in ejercicios" :key="ejercicio" :value="ejercicio">{{ ejercicio }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="dropdown-modelos">{{ $t("registro-declaraciones.seleccione-modelo") }}</label>
          <select class="form-select" v-model="modeloSeleccionado">
            <option selected></option>
            <option v-for="modelo in modelos" :key="modelo.valor" :value="modelo.valor">
              {{ modelo.nombre }}
            </option>
          </select>
          <div class="text-danger mt-0" v-if="sinModeloSeleccionado">
            {{ $t("registro-declaraciones.error-seleccione-modelo") }}
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" style="margin-top: 40px;" @click="callPythonFunction">
        {{ $t("registro-declaraciones.descargar") }}
      </button>
      <textarea v-model="loggingPython" readonly class="log-output"></textarea>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

// Configuración global de Axios
axios.defaults.withCredentials = true;

export default {
  data() {
    return {
      datosCliente: [],
      certificados: [],
      ejercicios: [],
      modelos: [
        { valor: 'S90', nombre: '(S90) Impuesto de Sociedades' },
        { valor: '190', nombre: '(190) Rendimientos del trabajo' },
        { valor: 'CAT06', nombre: '(CAT06) Aplazamientos' },
        { valor: 'F93', nombre: '(F93) Declaración de Renta' },
        { valor: 'Notificaciones', nombre: 'Notificaciones' }
      ],
      todosLosClientes: true,
      clienteSeleccionado: null,
      certificadoSeleccionado: null,
      ejercicioSeleccionado: null,
      modeloSeleccionado: null,
      sinCertificadoSeleccionado: false,
      sinClienteSeleccionado: false,
      sinEjercicioSeleccionado: false,
      sinModeloSeleccionado: false,
      loggingPython: '',
      idsCliente: [],
    };
  },
  mounted() {
    this.obtenerDatosCliente();
    this.obtenerCertificados();
    this.obtenerEjercicios();
    window.electron.ipcRenderer.on('update-log', (event, data) => {
      this.loggingPython = data;
    });
  },
  beforeUnmount() {
    window.electron.ipcRenderer.removeAllListeners('update-log');
  },
  methods: {
    async obtenerDatosCliente() {
      axios.get('https://back.biartechnology.com/descargadeclaraciones/clientes/')
        .then(response => {
          this.datosCliente = response.data;
        })
        .catch(error => {
          console.error('Error en la petición:', error);
        });
    },
    async obtenerCertificados() {
      this.certificados = [
        "33424020Y JUAN JOSE ITURAIN (R: B71390645)"
      ];
      if (this.certificados.length === 1) {
        this.certificadoSeleccionado = this.certificados[0];
      }
    },
    async obtenerEjercicios() {
      this.ejercicios = [
        "Trimestre1", "Trimestre2", "Trimestre3", "Trimestre4",
        "Anual", "Enero", "Febrero", "Marzo", "Abril", "Mayo",
        "Junio", "Julio", "Agosto", "Septiembre", "Octubre",
        "Noviembre", "Diciembre"
      ];
    },
    limpiarSeleccion() {
      if (this.todosLosClientes) {
        this.clienteSeleccionado = null;
      }
    },
    async iniciarDescarga() {
      if (!this.certificadoSeleccionado) {
        this.sinCertificadoSeleccionado = true;
        return;
      }
      this.sinCertificadoSeleccionado = false;

      if (!this.todosLosClientes && !this.clienteSeleccionado) {
        this.sinClienteSeleccionado = true;
        return;
      }
      this.sinClienteSeleccionado = false;

      // 👇️ Comentado temporalmente para que se pueda dejar vacío el campo de modelo
      // if (!this.modeloSeleccionado) {
      //   this.sinModeloSeleccionado = true;
      //   return;
      // }
      // this.sinModeloSeleccionado = false;

      const datosClientes = this.todosLosClientes 
        ? this.datosCliente 
        : [this.datosCliente.find(c => c.id === this.clienteSeleccionado)];

      const argumentosPython = {
        certificado: this.certificadoSeleccionado,
        ejercicio: this.ejercicioSeleccionado,
        modelo: this.modeloSeleccionado,
        clientes: datosClientes.map(cliente => ({
          nombre: cliente.nombre,
          nif: cliente.nif,
          carpetaDescarga: cliente.carpeta_descarga,
          anioDescarga: cliente.anio_descarga
        }))
      };

      window.electron.ipcRenderer.send('run-python-function', argumentosPython);
    },
    callPythonFunction() {
      // Este método puede quedarse vacío si toda la lógica está en iniciarDescarga
    }
  },
};
</script>

<style scoped>
.descarga-declaraciones {
  margin-left: 40px;
  margin-right: 40px;
}

.contenedor-clientes {
  max-height: 280px;
  max-width: 100%;
  overflow-y: auto;
  font-size: 14px;
}

.tabla-clientes thead th {
  background-color: #f2f2f2;
  top: 0;
}

.tabla-clientes th,
.tabla-clientes td {
  padding: 8px;
}

.disabled-container {
  opacity: 0.5;
}

.disabled-label {
  color: gray;
}

.disabled-select {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.log-output {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
}
</style>